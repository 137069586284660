$grid-gutter-width: 30px; // default Bootstrap
$grid-gutter-width-mobile: ceil($grid-gutter-width / 4);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
);

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Custom gutter mobile
@media (max-width: map-get($grid-breakpoints, lg)) {
  .row:not(.no-gutters) {
    margin-left: -$grid-gutter-width-mobile;
    margin-right: -$grid-gutter-width-mobile;
  }

  .row:not(.no-gutters)>.col,
  .row:not(.no-gutters)>[class*='col-'] {
    padding-left: $grid-gutter-width-mobile;
    padding-right: $grid-gutter-width-mobile;
  }
}

.row-cards.row-cards {
  margin-left: -#{map-get($spacers, 2)};
  margin-right: -#{map-get($spacers, 2)};

  .col,
  [class*='col-'] {
    margin-bottom: map-get($spacers, 3);
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }
}

.row-inputs.row-inputs {
  margin-left: -#{map-get($spacers, 3)};
  margin-right: -#{map-get($spacers, 3)};

  .col,
  [class*='col-'] {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }
}

//Edit container size

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1920px !important;
    padding: 0 23px !important;
  }

  .container-medium {
    max-width: 1200px !important;
  }

}
